import { defaultValueLabelSetting, getAxisTooltip, getAxisV, getFormattedValue, getYLineSeriesData } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        labelLayout: { hideOverlap: true },
        series: getYLineSeriesData(data).map((series, index) => {
            return {
                type: "line",
                name: data.series_labels[index],
                data: series,
                label: defaultValueLabelSetting("top", baseFontSize, config,
                    value => getFormattedValue(value.data.value, "y", data, config)),
                connectNulls: true,
            };
        }),
    };
};
