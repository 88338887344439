import {
    defaultValueLabelSetting,
    formatTooltipValue,
    getAxisV,
    getFormattedValue,
    getItemTooltip, getTooltipLabel, getWaterfallSeriesColors,
    getYSeriesData,
} from "./common";

export function isExtreme(index, series) {
    return index === 0 || index === series.length - 1;
}

export function isPositive(value) {
    return value >= 0;
}

export function cutoffYAxis(ySeries) {
    const cumulative = ySeries.map((series) => {
        // know the y axis value of each point on the chart, we recursively add all data values (except the extremes)
        let cumul = 0;
        return series.map((entry, index) => {
            cumul += entry.value;
            return index === series.length - 1 ? entry.value : cumul;
        });
    });

    // list with non-extreme chart values, that is, the variance values
    // we use the absolute because we want to know the largest bar (maxDims)
    const ySeriesAbs = ySeries.map((series) => {
        return series.slice(1, -1).map(entry => Math.abs(entry.value));
    });

    let min = Math.min(...cumulative[0]);
    let max = Math.max(...cumulative[0]);
    const maxDims = Math.max(...ySeriesAbs[0]);

    const minMultiplier = [1e12, 1e9, 1e6, 1e3, 1]
        .find(data => min >= data) || 1;
    const maxMultiplier = [1e12, 1e9, 1e6, 1e3, 1]
        .find(data => Math.abs(max) >= data) || 1;

    min = Math.round(min / minMultiplier * 100) / 100;
    max = Math.round(max / maxMultiplier * 100) / 100;
    const minMaxDims = Math.round(maxDims / minMultiplier * 100) / 100;
    const maxMaxDims = Math.round(maxDims / maxMultiplier * 100) / 100;

    // round value to be a multiple of 10: 10, 20, 30, .. or, if the granularity is smaller, a multiple of 2: 2, 4, 6, 8, 10...
    let minAxis = min <= minMaxDims ? 0 : (Math.round((min - minMaxDims) / 10) * 10);
    let maxAxis = Math.abs(max) <= maxMaxDims ? 0 : (Math.round((Math.abs(max) - maxMaxDims) / 10) * 10);
    // minAxis constraint : it needs to be smaller than the min data value but greater than 0
    // maxAxis constraint : it needs to be greater than the max data value but smaller than 0
    // add two decimal digits because of the thinner granularity
    minAxis = minAxis < min && minAxis >= 0 ? minAxis : (Math.round(((min - minMaxDims) / 2) * 100) * 2) / 100;
    maxAxis = maxAxis < Math.abs(max) && maxAxis >= 0 ? maxAxis : (Math.round(((Math.abs(maxAxis) - maxMaxDims) / 2) * 100) * 2) / 100;

    return {
        // auto scale (undefined) Y axis
        min: min >= 0 && max >= 0 ? minAxis * minMultiplier : undefined,
        max: min <= 0 && max <= 0 ? -maxAxis * maxMultiplier : undefined,
    };
}

export const getTooltipLabelWithDimension = (id, data, config) => {
    if (data.metadata[id]) {
        if (id === null) {
            return data.metadata[id]?.dimension + " " + config.i18n.chart.label["__null__"];
        } else {
            return data.metadata[id]?.dimension + " " + data.metadata[id]?.name;
        }
    } else {
        return getTooltipLabel(id, data, config);
    }
};

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    const ySeriesData = getYSeriesData(data);

    // the bottom stack is transparent (visual hack to seem a waterfall)
    const transparentSeries = ySeriesData.map((series) => {
        let sum = 0;
        let prev = 0;
        series = series.map((entry) => {
            sum += prev;
            prev = entry.value;
            return sum;
        });
        series[series.length - 1] = 0;
        return series;
    });

    // all series have the same size, so we can iterate positiveData and use its index
    const seriesToPlot = ySeriesData.flatMap((series, index) => [
        {
            name: "transparent",
            type: "bar",
            stack: "waterfall",
            itemStyle: {
                borderColor: "transparent",
                color: "transparent",
            },
            data: transparentSeries[index],
        },
        {
            name: "data_series",
            type: "bar",
            stack: "waterfall",
            // stacks positive values at the inside-bottom of a negative transparent, and
            // at the top of a positive transparent. Does the opposite for negative values.
            stackStrategy: "all",
            data: series,
            itemStyle: {
                color: value => getWaterfallSeriesColors(value, series, "VARIANCE"),
            },
            label: defaultValueLabelSetting("top", baseFontSize, config,
                value => getFormattedValue(value.data.value, "y", data, config)),
        }],
    );

    // random name to stack series
    return {
        ...getAxisV(data, config, baseFontSize, 1),
        yAxis: {
            ...getAxisV(data, config, baseFontSize, 1).yAxis,
            ...cutoffYAxis(ySeriesData),
        },

        tooltip: {
            ...getItemTooltip(data, config, baseFontSize).tooltip,
            formatter: (params) => {
                // transparent series does not have tooltip !
                if (params.seriesName !== "transparent") {
                    const name = formatTooltipValue(data, "x", getTooltipLabelWithDimension(params.data.id, data, config), config);

                    return `${name} <br/> ${params.marker}${formatTooltipValue(data, "y", params.value, config)}`;
                }
            },
        },

        series: seriesToPlot,
    };
};
