import { defaultValueLabelSetting, getFormattedValue, getLegend, getNumericalAxisOptions } from "../common";
import { getAxisTooltip as getTimeAxisTooltip, getForecastingLine, getTimeSeriesAxisOptions, getYSeries as getTimeYSeries } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    let chartOptions = {
        ...getLegend(data, config, baseFontSize),
        ...getTimeAxisTooltip(data, config, baseFontSize),
        xAxis: getTimeSeriesAxisOptions(data.labels.x, data.parameters.DATE_AGG, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        ...getTimeYSeries(data, "bar", baseFontSize, config, true, {
            ...getForecastingLine(data, config, baseFontSize),
        }),
    };

    // Min & Max labels are not formatted to the appropriate time format, so they are not shown
    chartOptions.xAxis.axisLabel.showMaxLabel = false;
    chartOptions.xAxis.axisLabel.showMinLabel = false;

    // Add total label at the top of the columns
    // We need a new series in the chart that doesn't change the chart itself (hence the zero value) but it allows us to show an additional label.
    const sumSeries = data.result.map((dateEntry) => {
        const date = dateEntry.x.toDate();
        return {
            id: date,
            name: date,
            value: [date, 0], // Charts with dates as X values need to be formatted differently
        };
    });

    chartOptions.series.push(
        {
            ...chartOptions.series[0],
            data: sumSeries,
            label: {
                ...defaultValueLabelSetting("top", baseFontSize, config, (entry) => {
                    const id = entry.data.name;
                    const sum = data.result.find(r => r.label.isSame(id)).y.reduce((a, b) => a + b, 0);
                    return getFormattedValue(sum, "y", data, config);
                }),
            },
        },
    );

    return chartOptions;
};
