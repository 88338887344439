import { getAxisTooltip, getForecastingLine, getTimeSeriesAxisOptions, getYSeriesData } from "./common";
import { defaultValueLabelSetting, getFormattedValue, getLegend, getNumericalAxisOptions } from "../common";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    return {
        xAxis: getTimeSeriesAxisOptions(data.labels.x, data.parameters.DATE_AGG, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(data, config, baseFontSize),
        labelLayout: { hideOverlap: true },
        series: getYSeriesData(data).map((series, index) => {
            return {
                type: "line",
                name: data.series_labels[index],
                data: series,
                symbolSize: 0.15 * baseFontSize,
                lineStyle: { width: 0.05 * baseFontSize },
                label:
                    defaultValueLabelSetting(
                        "top",
                        baseFontSize,
                        config,
                        (value) => {
                            const yVal = value.data.value[1]; // value[0] is x-value (the timestamp), value[1] the y-value
                            return getFormattedValue(yVal, "y", data, config);
                        }),
                ...getForecastingLine(data, config, baseFontSize),
            };
        }),
    };
}
