import { defaultLabelFontSize, defaultValueLabelSetting, displayStatLines, getAxisTooltip, getAxisV, getFormattedValue, getYSeries } from "./common";
import { getChartOptions as getTimelineColumnOptions } from "./timeline/timelineColumn";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    // Column chart can have both time axis and categorical axis
    if (data.label_type.x === "DATE") {
        return getTimelineColumnOptions(title, statisticalLines, data, config, baseFontSize);
    } else {
        return {
            ...getAxisV(data, config, baseFontSize),
            ...getAxisTooltip(data, config, baseFontSize),
            ...getYSeries(data, "bar", baseFontSize, config, false, {
                label: defaultValueLabelSetting("top", baseFontSize, config, value => getFormattedValue(value.data.value, "y", data, config)),
                markLine: { // Categorical results may have statistical lines
                    data: statisticalLines && data.variables ? displayStatLines(statisticalLines, data.variables, config) : [],
                    symbol: "none",
                    lineStyle: {
                    // this style will be applied to all lines
                        width: 2,
                    },
                    label: {
                        fontSize: defaultLabelFontSize(baseFontSize),
                        formatter: function (params) {
                            return params.name + " : " + getFormattedValue(params.data.yAxis, "y", data, config);
                        },
                        position: "insideEndTop",
                    },
                    animation: false,
                    silent: true,
                },
            }),
            labelLayout: { hideOverlap: true },
        };
    }
};
