import { defaultValueLabelSetting, getAxisTooltip, getAxisV, getFormattedValue, getLegend, getYSeries } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    let chartOptions = {
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(data, config, baseFontSize),
        ...getYSeries(data, "line", baseFontSize, config, false, {
            areaStyle: {},
        }),
    };

    // Add total label at the top of the stacked chart
    chartOptions.series.map((seriesEntry, index) => {
        if (index === chartOptions.series.length - 1) {
            seriesEntry.label = defaultValueLabelSetting("top", baseFontSize, config, (entry) => {
                const id = entry.data.name;
                const sum = data.result.find(r => r.label.isSame(id)).y.reduce((a, b) => a + b, 0);

                return getFormattedValue(sum, "y", data, config);
            });
        }

        return seriesEntry;
    });
};
