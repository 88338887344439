import { getAxisTooltip, getForecastingLine, getTimeSeriesAxisOptions, getYSeries } from "./common";
import { defaultValueLabelSetting, getFormattedValue, getLegend, getNumericalAxisOptions } from "../common";
import { getChartOptions as getAreaChartOptions } from "../stackedArea";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    // If x type is not date, then we return an area chart, instead of stacked timeline.
    if (data.label_type.x !== "DATE") {
        return getAreaChartOptions(title, statisticalLines, data, config, baseFontSize);
    } else {
        let chartOptions = {
            xAxis: getTimeSeriesAxisOptions(data.labels.x, data.parameters.DATE_AGG, baseFontSize),
            yAxis: getNumericalAxisOptions(data.labels.y, data, "y", config, baseFontSize),
            ...getAxisTooltip(data, config, baseFontSize),
            ...getLegend(data, config, baseFontSize),
            ...getYSeries(data, "line", baseFontSize, config, false, {
                areaStyle: {},
                triggerLineEvent: true,
                ...getForecastingLine(data, config, baseFontSize),
            }),
        };

        // Add total label at the top of the stacked chart
        chartOptions.series.map((seriesEntry, index) => {
            if (index === chartOptions.series.length - 1) {
                seriesEntry.label = defaultValueLabelSetting("top", baseFontSize, config, (entry) => {
                    const id = entry.data.name;
                    const sum = data.result.find(r => r.label.isSame(id)).y.reduce((a, b) => a + b, 0);

                    return getFormattedValue(sum, "y", data, config);
                });
            }
            return seriesEntry;
        });

        return chartOptions;
    }
}
