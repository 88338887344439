import { defaultValueLabelSetting, getAxisTooltip, getAxisV, getFormattedValue, getLegend, getYSeries } from "./common";
import { isEqual } from "lodash";
import { getChartOptions as getStackedTimelineColumnOptions } from "./timeline/stackedTimelineColumn";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    if (data.label_type.x === "DATE") {
        return getStackedTimelineColumnOptions(title, statisticalLines, data, config, baseFontSize);
    } else {
        let chartOptions = {
            ...getLegend(data, config, baseFontSize),
            ...getAxisTooltip(data, config, baseFontSize),
            ...getAxisV(data, config, baseFontSize),
            ...getYSeries(data, "bar", baseFontSize, config, true),
        };

        // Add total label at the top of the columns
        // We need a new series in the chart that doesn't change the chart itself (hence the zero value) but it allows us to show an additional label.
        const sumSeries = data.result.map((dateEntry) => {
            return {
                id: dateEntry.x,
                name: dateEntry.x,
                value: 0,
            };
        });

        chartOptions.series.push(
            {
                ...chartOptions.series[0],
                data: sumSeries,
                label: {
                    ...defaultValueLabelSetting("top", baseFontSize, config, (entry) => {
                        const id = entry.data.name;
                        const sum = data.result.find(r => isEqual(r.label, id)).y.reduce((a, b) => a + b, 0);

                        return getFormattedValue(sum, "y", data, config);
                    }),
                },
            },
        );
        return chartOptions;
    }
};
