import { getItemTooltip, getPieChartData } from "./common";
import { roundToDecimalCase } from "../../util/util";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        ...getItemTooltip(data, config, baseFontSize),
        series: getPieChartData(data, config).map(series =>
            ({
                type: "pie",
                data: series,
                top: 10,
                label: {
                    fontSize: 0.3 * baseFontSize,
                    lineHeight: 0.5 * baseFontSize,
                    formatter: function (params) {
                        const percentage = roundToDecimalCase(params.percent);
                        return [params.name, "\n", percentage, "%"].join("");
                    },
                },
            })),
    };
};
