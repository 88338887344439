import { getAxisHV, getPointSeries, getPointTooltip } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        ...getAxisHV(data, config, baseFontSize),
        ...getPointTooltip(data, config, baseFontSize),
        series: getPointSeries(data).map(series =>
            ({
                type: "scatter",
                data: series,
                symbolSize: baseFontSize / 3.0,
                clip: false,
            })),
    };
};
