import { defaultValueLabelSetting, getFormattedValue, getNumericalAxisOptions } from "../common";
import { getAxisTooltip as getTimeAxisTooltip, getForecastingLine, getYSeries as getTimeYSeries, getTimeSeriesAxisOptions } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    let chartOptions = {
        ...getTimeAxisTooltip(data, config, baseFontSize),
        xAxis: getTimeSeriesAxisOptions(data.labels.x, data.parameters.DATE_AGG, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        ...getTimeYSeries(data, "bar", baseFontSize, config, false, {
            label: defaultValueLabelSetting("top", baseFontSize, config,
                // value[0] is x-value (the timestamp), value[1] the y-value
                value => getFormattedValue(value.data.value[1], "y", data, config)),
            ...getForecastingLine(data, config, baseFontSize), // time results may have forecasting line
        }),
        labelLayout: { hideOverlap: true },
    };

    // Min & Max labels are not formatted to the appropriate time format, so they are not shown
    chartOptions.xAxis.axisLabel.showMaxLabel = false;
    chartOptions.xAxis.axisLabel.showMinLabel = false;

    return chartOptions;
};
