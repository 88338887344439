import {
    defaultValueLabelSetting,
    getAxisTooltip,
    getAxisV,
    getFormattedValue,
    getWaterfallSeriesColors,
    getYSeriesData,
} from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    const ySeriesData = getYSeriesData(data);
    const ySeriesDataWithTotals = ySeriesData.map(series => [
        ...series.map((entry) => {
            return {
                id: entry.id,
                name: entry.name,
                positive: entry.value >= 0,
                value: entry.value,
            };
        }),
        {
            id: "__total__",
            name: "__total__",
            value: series.reduce((sum, entry) => sum + entry.value, 0),
            positive: series.reduce((sum, entry) => sum + entry.value, 0) >= 0,
        },
    ]);

    // the bottom stack is transparent (visual hack to seem a waterfall)
    const transparentSeries = ySeriesData.map((series) => {
        let counter = 0;
        return series.map((entry) => {
            const item = counter;
            counter += entry.value;
            return item;
        });
    });

    // add total to the end of the dataset
    const dataWithTotal = {
        ...data,
        result: [
            ...data.result,
            {
                id: "__total__",
                label: "__total__",
                x: "__total__",
                y: ySeriesDataWithTotals.map(series => series[series.length - 1].value),
            },
        ],
    };

    const seriesToPlot = ySeriesDataWithTotals.flatMap((series, index) => [
        {
            name: "transparent",
            type: "bar",
            stack: "waterfall",
            stackStrategy: "all",
            itemStyle: {
                borderColor: "transparent",
                color: "transparent",
            },
            data: transparentSeries[index],
        },
        {
            name: "values",
            type: "bar",
            stack: "waterfall",
            stackStrategy: "all",
            itemStyle: {
                color: value => getWaterfallSeriesColors(value, series, "WATERFALL"),
            },
            data: series,
            label: defaultValueLabelSetting("top", baseFontSize, config,
                value => getFormattedValue(value.data.positive ? value.data.value : -value.data.value, "y", data, config)),
        }],
    );
    // random name to stack series
    return {
        ...getAxisV(dataWithTotal, config, baseFontSize, 1),
        ...getAxisTooltip(dataWithTotal, config, baseFontSize),

        series: seriesToPlot,
    };
};
