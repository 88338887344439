import { getChartOptions as getLineChartOptions } from "../line";
import { getChartOptions as getTimelineCompare } from "./timelineCompare";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    // If x type is not date, then we return a line chart, instead of timeline.
    if (data.label_type.x !== "DATE") {
        return getLineChartOptions(title, statisticalLines, data, config, baseFontSize);
    } else {
        return getTimelineCompare(title, statisticalLines, data, config, baseFontSize);
    }
}
